<template>
<div class="w-100 mt-10">
  <div class="d-block md:flex justify-between">


    <div class="flex gap-2 justify-center md">

         <!-- <vs-tooltip text=" ساخت سرور جدید  ">
          <vs-button
          class="rounded text-sm md:text-base py-1 md:py-2"
          color="#1791DA" gradient-color-secondary="#0092e1" type="gradient" @click="servicePopup = !servicePopup">سرویس جدید</vs-button>

        </vs-tooltip> -->

    </div>

  </div>
  <vs-card style="flex-direction: column-reverse" class="shadow-lg mr-2 p-1 lg:w-12/12 md:w-12/12 sm:w-12/12 sm:flex-col  rounded-lg shadow-sm bg-h-primary-light light-border">
    <div style="flex-direction: column" class="flex lg:flex-row sm:flex-column md:flex-column ">
      <div class="w-100 flex justify-content-start flex-col lg:ml-5 ">
        <div class="flex my-3 justify-content-between items-center ">
          <div>
            <vs-button to="/docker/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2"> کلاستر جدید</vs-button>

          </div>

        </div>
        <br />
        <div class="mt-5">
          <div style="width: 100%">
            <div v-if="this.cloudList === '' " class="text-center">
              <span class="text-lg mb-4 block">شما درحال حاضر سرویسی ندارید</span>
              <div>
                <vs-button to="/docker/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2"> کلاستر جدید</vs-button>
              </div>
            </div>
            <div
             v-for="cloud in cloudList"
             :key="cloud.id"
             class="row bg-h-primary mx-3 mb-2 rounded-lg p-3 items-center justify-between md:justify-center">

             <div class="flex justify-start items-center  w-full lg:w-1/3">
              <div class="flex gap-4 items-center w-10/12 lg:w-1/3">
                <div v-if="cloud.nodes[0].node_status_title === 'آماده استفاده'">
                  <div class="bg-h-success rounded-full w-4 h-4"></div>
                </div>

                <div v-else-if="cloud.nodes[0].node_status_title !== 'آماده استفاده'">
                  <div class="bg-h-danger rounded-full w-4 h-4"></div>
                </div>

                <div>
                  <router-link class="text-right text-sm md:text-lg text-white ir-yekan-bold cursor-pointer"  :to="`/docker/info/${cloud.id}`" >{{ cloud.group_title }}</router-link>


                </div>
              </div>

              <div class="w-1/2 text-right">
              </div>

            </div>
                <div class="flex items-center mt-3 md:mt-0 w-full md:w-1/3 justify-center"
                v-if="cloud.nodes[0].node_status_title !== 'در حال آماده سازی'"        >

                <div :id="'delete' + cloud.id" @click="setDeletePrompt(cloud.id), deletePrompt = true, id = cloud.id"
                class="hover:shadow-xl m-2 p-2  hover:text-white cursor-pointer">
                <b-tooltip variant="info" :target="'delete' + cloud.id" triggers="hover">
                  حذف کلاستر
                </b-tooltip>
                <b-icon class="my-2" scale="2" icon="trash"></b-icon>
              </div>

              </div>
              <div v-else id="load">
                <b-tooltip variant="info" target="load" triggers="hover">
                  {{getFlag(cloud.nodes[0].node_status)}}
                  در حال ساخت
                </b-tooltip>
                <b-icon
                  class="h1 text-dark"
                  icon="gear"
                  animation="spin"
                ></b-icon>
              </div>

                <div class="flex flex-wrap justify-center lg:justify-end md:mt-4 lg:mt-0 items-center gap-4 w-full md:w-full lg:w-1/3">

                  <div>
                    <div class=" bg-h-primary-light rounded p-2 text-center ">
                      <!-- <b-button  class="bg-h-success text-primary text-xs btn-xs border-dashed">{{  }}</b-button> -->

                    <span  @click="copyToClipboard(cloud.nodes[0].vm_master_ip)" class="text-white text-sm md:text-lg cursor-pointer"> {{ cloud.nodes[0].vm_master_ip }}</span>


                    <span class="text-grey font-light text-xs block">آیپی مستر</span>

                    </div>
                  </div>


                  <router-link
                    :id="'details' + cloud.id"
                    class="hover:shadow-xl m-1 text-grey cursor-pointer"
                    :to="`/docker/info/${cloud.id}`">
                    <b-tooltip variant="info" :target="'details' + cloud.id" triggers="hover">
                      مشخصات سرور
                    </b-tooltip>
                      <b-icon class="my-2" scale="2" icon="arrow-left-square" />
                  </router-link>
                </div>
            </div>


          </div>
          <vs-pagination
          class="center w-full"
          :total="countPage"
          v-model="pageNumber"
        />
        </div>
      </div>
    </div>
</vs-card>
    <!--end  prompt area-->
    <div>
      <vs-popup :active.sync="deletePrompt" title="حذف  کلاستر">
        <div class="con-exemple-prompt">
          <!-- <div v-if="smsConfirmStatus === 1">
            <p>
              کد تایید ارسالی به ایمیل یا (شماره همراه) خود را در فیلد زیر وارد نمایید.
            </p>
            <vs-input class="w-full mb-3" v-model="requestCode" label-placeholder="کد تایید"></vs-input>
          </div> -->
          <div >
            <p>آیا از حذف اطمینان دارید. ؟</p>
          </div>
          <vs-button icon="delete" class="float-right rounded w-25" color="danger"
            @click="deleteRecord(id)">حذف</vs-button>
        </div>
      </vs-popup>
    </div>
</div>
</template>

<script>
// import FloatIp from '../../apps/haiocloud/viracloud/viradetails/FloatIp.vue'
import CountTo from 'vue-count-to'

export default {
  name: 'FastAccess',
  components: {
    // FloatIp,
    CountTo
  },
  data () {
    return {
      startVal: 1,
      verifyoffCode:'',
      amountCharge : 80000,
      offPercent:'',
      offValueCalculate: '',
      encteCode: false,
      offCode:'',
      popupActiveReset: false,
      shutdownPrompt: false,
      pageNumber: 0,
      cloudSelected: {},
      search: '',
      deletePrompt: false,
      sumCal: '',
      priceAmount: '',
      loading:false,
      ipPrompt:false,
      activePrompt : false,
      creditPrompt : false,
      selectedID: 0,
      cloudList: [],
      flag: '',
      countPage: 1,
      serverIdreboot: null,
      servicePopup: false,
      serverType: '',
      VDILink:'',
      vdi:false,
      LoginToVDIPopup: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    realPrice () {
      return ((this.amountCharge * 1) + (0.10 * this.amountCharge))
    },
    giftedAmount () {
      if (this.amountCharge >= 1000000 && this.amountCharge <= 1999999) {
        return (this.amountCharge * 10 / 100)
      }
      if (this.amountCharge > 1999999 && this.amountCharge <= 2999999) {
        return (this.amountCharge * 15 / 100)
      }
      if (this.amountCharge > 2999999 && this.amountCharge <= 3999999) {
        return (this.amountCharge * 20 / 100)
      }
      if (this.amountCharge > 3999999 && this.amountCharge <= 5999999) {
        return (this.amountCharge * 25 / 100)
      }
      if (this.amountCharge > 5999999 && this.amountCharge <= 9999999) {
        return (this.amountCharge * 30 / 100)
      }
      if (this.amountCharge > 9999999 && this.amountCharge <= 19999999) {
        return (this.amountCharge * 35 / 100)
      }
      if (this.amountCharge > 19999999 && this.amountCharge <= 29999999) {
        return (this.amountCharge * 40 / 100)
      }
      if (this.amountCharge >= 30000000) {
        return (this.amountCharge * 50 / 100)
      }
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    smsConfirmStatus () {
      return this.$store.state.AppActiveUser.sms_confirm_action
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    pageNumber () {
      this.getCloudList()
    },
    $route () {
      this.getCloudList()
    },
    search () {
      this.getCloudList()
    }
  },
  methods: {
    copyToClipboard (p) {
      navigator.clipboard.writeText(p).then(() => {
        this.$vs.notify({ text: ' کپی شد', color: 'success' })
      })
    },
    acceptAlert () {
      // const authtoken = localStorage.getItem('accessToken')
      this.$http.post('https://api.haio.ir/v1/invoice/charge/add', {
        amount: this.amountCharge,
        off_code: this.verifyoffCode,
        payment_side:'2',
        redirect_url: 'https://console.haio.ir/pages/payment'
      })
        .then(async (res) => {
          window.location = (res.data.params.payment_url)
          // console.log("Set follow txt record at your dns server:")
          // console.log(res.data.params)
        })
        .catch(error => {
          this.$vs.notify({
            color:'danger',
            title:' شارژ حساب',
            text:error.response.data.message
          })

        })
    },
    deleteRequestCode (vmId)   {
      this.$http
        .get(`/cloud/vm/${vmId}/delete/request`)
        .then((res) => {
          this.$vs.notify({
            color: 'success',
            text: res.data.message,
            title: 'حذف ویرا ابر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف ویرا ابر'
          })
        })
    },
    deleteRecord (id) {
      this.$http
        .delete(`/cloud/docker/cluster/${id}`, {
          confirm_code: this.requestCode
        })
        .then((res) => {
          this.getCloudList()
          this.$vs.notify({
            color: 'warning',
            text: res.data.message,
            title: 'حذف کلاستر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف کلاستر'
          })
        })
        .finally(() => {
          this.getCloudList()
          this.deletePrompt = false
        })
    },
    handleResetPropmt (item) {
      this.popupActiveReset = true
      this.serverIdreboot = item
    },
    setIpPrompt (id) {
      this.activePrompt = false
      this.ipPrompt = true
      this.cloudSelected = id
    },
    setIpPromtModal () {
      this.activePrompt = false
      this.ipPrompt = true
      this.cloudList.map((item) => {
        if (item.id === this.selectedID) {
          this.cloudSelected = item
        }
      })
    },
    setDeletePrompt (d) {
      this.selectedID = d
      this.activePrompt = false
      this.deletePrompt = true
      if (this.smsConfirmStatus === 1) {
        this.deleteRequestCode(d)
      }
    },

    getConsume () {
      this.loading = false
      this.$http.get('user/consume').then(res => {
        let pricer = 0
        Object.values(res.data.params).forEach((items) => {
          pricer += Object.values(items)[0]
        })
        /// checker
        setTimeout(() => {
          this.priceAmount = pricer
        }, 1000)
        this.$store.dispatch('PushDataConsume', res.data.params)
        // res.data.params.map((item) => {
        //   this.dataLabel = this.dataLabel.concat(Object.keys(item).toString())
        //   this.dataValue = this.dataValue.concat(Object.values(item))
        // })
        setTimeout(() => {
          res.data.params.forEach((int) => {
            this.sumCal += Object.values(int)[0]
          })
        }, 500)
        this.loading = true
        this.$store.dispatch('PushDataChart', this.dataLabel)
        this.dataStore = JSON.stringify(this.$store.state.DataChart)
      }).catch(() => {
      })
    },
    setRebootPrompt (d) {
      this.reboot(d)
      this.popupActiveReset = false
    },

    turnOff (id) {

      if (!this.vdi) {
        this.$http
          .get(`/cloud/vm/${id}/shutdown`)
          .then((res) => {
            this.shutdownPrompt = false
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })

          })
      } else {
        this.$http
          .get(`/cloud/vm/${id}/shutdown`)
          .then((res) => {
            this.shutdownPrompt = false
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })

          })
      }
    },
    gotoDetailsPage () {
      this.$router.push(`/apps/viracloud/${this.selectedID}`)
    },
    reboot (id) {
      this.rebootPrompt = false
      if (!this.vdi) {
        this.$http
          .get(`/cloud/vm/${id}/reboot`)
          .then((res) => {
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })
          })
      } else {
        this.$http
          .get(`/cloud/vm/${id}/reboot`)
          .then((res) => {
            this.vdi = false
            this.getCloudList()
            this.$vs.notify({
              color: 'success',
              title: ' ',
              text: res.data.message
            })
          })
          .catch((err) => {
            this.$vs.notify({
              color: 'danger',
              title: '  ',
              text: err.response.data.message
            })
          })
      }
    },
    getCloudList () {
      this.$vs.loading()
      this.$http
        .get(
          `/cloud/docker/cluster/?page=${this.pageNumber}&search=${this.search}`
        )
        .then((res) => {
          this.$vs.loading.close()
          const d = res.data.params
          this.countPage = d.last_page
          this.cloudList = d.data
        })
        .catch((err) => {
          this.$vs.loading.close()
          if (err.response.status === 404) {
            this.cloudList = []
            return
          }

          this.$vs.notify({
            color: 'danger',
            title: ' ویرا کلود',
            text: err.response.data.message
          })
        })
    },
    getFlag (status) {
      if (status === 1) {
        this.repeat = setTimeout(() => {
          this.getCloudList()
        }, 60000)
      }
    },
    getVDILink (id) {
      this.$http.get(`/cloud/vm/vdi/${id}`)
        .then((response) => {
          this.VDILink = response.data.params.url
        })
    }

  },

  async mounted () {
    await this.getCloudList()
    // await this.getVpc()
    await this.getFlag()
  },
  destroyed () {
    window.clearInterval(this.repeat)
  }
}
</script>

<style scoped>
.bg-box {
  background-color: #e9f1fa !important;
}
.white-light {
  background-color: #ffffff !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #98befb);
}
.hoverItem:hover {
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}


</style>
